@import url('https://fonts.googleapis.com/css?family=Arsenal:400,400i,700');

$primary: #e91a45; /* MAIN COLOR */
$secondary: #f28820; /* SECONDARY COLOR */

$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
body {
	font-family: 'Arsenal', sans-serif;
}
.flash {
	display:none;
}

nav {
	z-index: 1000;
}
.gradient {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3911f+1,e91a45+17,b71f5d+35,8c5ea7+53,f3911f+80,ed6524+100 */
	background: #f3911f; /* Old browsers */
	background: -moz-linear-gradient(45deg,  #f3911f 1%, #e91a45 17%, #b71f5d 35%, #8c5ea7 53%, #f3911f 80%, #ed6524 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg,  #f3911f 1%,#e91a45 17%,#b71f5d 35%,#8c5ea7 53%,#f3911f 80%,#ed6524 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg,  #f3911f 1%,#e91a45 17%,#b71f5d 35%,#8c5ea7 53%,#f3911f 80%,#ed6524 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3911f', endColorstr='#ed6524',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		font-size: 1.3em;
		font-weight: 100;
		margin-top: 25px;
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
		    height:22px;
		    display: inline-block;
				margin-top:0px;
				padding: 5px;
		}
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width:767px) {
	margin: 17px 15px 8px 0px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
h1 {
	font-size: 4em;
	@media(max-width:767px){
		font-size: 2.5em;
	}
	@media(max-width:767px){
		font-size: 2em;
	}
}
p {
	font-size: 2em;
	font-style: italic;
	@media(max-width:767px){
		font-size: 1.5em;
	}
	@media(max-width:460px){
		font-size: 1em;
	}
}
/** END LOGIN FORM **/

// start sections
section {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	text-align: center;
	@media(max-width:1024px){
		background-attachment: scroll;
	}
}
.section-a {
	padding: 250px 0;
	background-image: url('../img/bg1.jpg');
	color: $primary;
	h1{
		font-size: 6em;
		text-transform: capitalize;
			color: $secondary;
	}
	p {
		font-size: 3em;
	}
	@media(max-width:767px){
		padding: 100px 0;
		h1 {
			font-size: 3em;
		}
		p {
			font-size: 1.5em;
		}
	}
	@media(max-width:360px){
		padding: 50px 0;
		h1 {
			font-size: 2em;
		}
	}
}
.section-b{
	padding: 50px 0;
	color: $wht;
	@media(max-width:767px){
		padding: 20px 0;
	}
}
.section-c{
	background-image: url('../img/bg2.jpg');
	padding: 150px 0;
	color: $wht;
	@media(max-width:767px){
		padding: 50px 0;
	}
	@media(max-width:360px){
		padding: 20px 0;
	}
}
.section-d{
	padding: 100px 0;
	color: $wht;
	a {
		color:$wht;
		text-decoration: none;
	}
	img{
		width:95%;
		margin: 25px auto; ;
		box-shadow: 0px 0px 20px $wht;
		@media(max-width:767px){
			width: 50%;
		}
		@media(max-width:560px){
			width: 75%;
		}
	}
	@media(max-width:767px){
		padding: 50px 0;
	}
}
// end sections
// cta
	.cta-box {
		margin-top: 45px ;
		a {
			background: $primary;
			padding: 25px 30px;
			color:$wht;
			font-size: 1.5em;
			font-weight: 400;
			text-decoration: none;
			&:hover {
				background: $secondary;
				font-weight: 700;
			}
			@media(max-width:460px){
				padding:15px 15px;
				font-size: 1em;
			}
		}
	}
// cta ends
footer {
	padding: 30px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 1em;
	p {
		font-size: 1em;
	}
	a {
		color: $footerLinks;
		&:hover {
			color: lighten($footerLinks, 10%);
			text-decoration: none;
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
